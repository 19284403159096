<template>

    <div class="user">
        <h4>编辑基本信息</h4>
        <div class="editinfo">
            <div class="e1">
               
                 <div>
                    <div><p>真实姓名</p><span>*</span></div>
                    <input type="text" v-model="form.real_name" placeholder="请填写你的真实姓名">
                </div>
            </div>
            <div class="e1">
                <div>
                    <div><p>性 别</p><span>*</span></div>
                    <div style="width: 240px;margin: 0 60px 0 20px;">
                       <div class="flex-around" @click="form.sex = 1">
                        <img src="../../assets/public/check1.png" v-if="form.sex == 1" />
                        <img src="../../assets/public/check2.png" v-if="form.sex == 0" />
                        <p style="margin-left:10px">男</p>
                        </div>

                        <div class="flex-around" @click="form.sex = 0">
                        <img src="../../assets/public/check1.png" v-if="form.sex == 0" />
                        <img src="../../assets/public/check2.png" v-if="form.sex == 1" />
                        <p style="margin-left:10px">女</p>
                        </div>
                    </div>
                    
                </div>
                 <div>
                    <div><p>出生日期</p><span>*</span></div>
                    <div style="">
                        <!-- <el-date-picker v-model="form.birthday"
                        format="yyyy 年 MM 月 dd 日"
                         value-format="yyyy-MM-dd"
                        type="date" placeholder="选择日期">
                        </el-date-picker> -->
                        <input type="text" :value="form.birthday" placeholder="请添加身份证信息获取" disabled>
                    </div>
                    
                </div>
            </div>
            <!-- 证件信息 -->
            <div class="e1">
                <div>
                    <div><p>证件信息</p><span>*</span></div>
                    <p class="addcard" @click="addcard">添加证件信息</p>
                </div>
                
            </div>
            <div class="e1" v-for="(item,i) in cardlist" :key="i">
                <div>
                    <div><p></p></div>
                    
                    <el-select v-model="item.type" placeholder="请选择">
                        <el-option
                        v-for="it in options"
                        :key="it.value"
                        :label="it.label"
                        :value="it.label">
                        </el-option>
                    </el-select>
                </div>
                 <div style="margin-left:65px">
                    <div><p>证件号码</p><span>*</span></div>
                    <input type="text" v-model="item.card_id" placeholder="请填写正确的证件号码">
                </div>
                <div class="addcard" @click="del(i)" v-if="i!=0">
                    删除
                </div>
            </div>
          
            <div class="e2">
                
                <div>个性签名</div>
                <textarea placeholder="快来给自己写个超级酷炫的签名吧~" v-model="form.autograph">
                    
                </textarea>
               
            </div>
        </div>


        <div class="but">
            <div class="edit" @click="tourl2">
               取消
            </div>
            <div class="editpass" @click="edituserview">
                保存
            </div>
        </div>
    </div>

</template>

<script>
import { userEditAPI,userViewAPI} from "@/api/user";
export default {

  data () {
    return {
      sign: 1,
      form:{
          real_name:"",
          mailbox:"",
          card_id:[],
          autograph:"",
          phone:"",
          sex:1,
          birthday:"",
          phone_code:""
      },
      cardlist:[],
      options: [{
          value: 1,
          label: '身份证'
        }, {
          value: 2,
          label: '军官证'
        },{
          value: 3,
          label: '港澳通行证'
        }, {
          value: 4,
          label: '护照'
        },{
          value: 5,
          label: '回乡证'
        }, {
          value: 6,
          label: '台胞证'
        }]

    }
  },
  created () {
      this.getuserview()
  },
  methods: {
   addcard(){
       var obj= {"type":"身份证","card_id":""}
       this.cardlist.push(obj)
   },
    del(i){
      
       this.cardlist.splice(i,1)
   },
     tourl2 () {
      this.$router.push({ path: '/user/personinfo' })
    },
    edituserview(){	
        if( this.form.real_name==""){
            this.$message.info("内容不能为空")
            return 
        }
        if(this.cardlist.length<1){
            this.$message.info("请添加至少一个证件信息")
            return 
        }
        this.form.card_id=JSON.stringify(this.cardlist)
        
        var idcard=""
        for (const i in this.cardlist) {
            if (this.cardlist[i].card_id=="") {
                this.$message.info("证件号不能为空")
                 return        
            }
            if (this.cardlist[i].type=="身份证") {
                idcard=this.cardlist[i].card_id     
            }
        }
        if (idcard=="") {
            this.$message.info("身份证证件号必填")
            return   
        }
        var reg=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
        if(reg.test(idcard)){ 
            
           this.form.birthday= idcard.substring(6, 10) +  "-" + idcard.substring(10, 12) + "-" + idcard.substring(12, 14);
        }else{
            this.$message.info("请填写正确的身份证号")
            return
        }

        for (var i = 0; i < this.cardlist.length - 1; i++) {
            if (this.cardlist[i].card_id=="") {
                this.$message.info("证件号不能为空")
                 return        
            }
            for (var j = i + 1; j < this.cardlist.length; j++) {              
              if (this.cardlist[i].type == this.cardlist[j].type) {                
                this.$message('证件类型不能重复')             
                return 
              }
            }
        }
       setTimeout(()=>{
           userEditAPI(this.form).then(res=>{
             this.$message({
              message: "保存成功",
              type: "success"
            });
            this.tourl2()
        })
       },100)
        
	},
     getuserview(){		
        userViewAPI().then(res=>{
            this.form=res.user
            
            this.cardlist=res.user.card_id ? res.user.card_id : []
            this.form.birthday=res.user.birthday=="0" ? "" : res.user.birthday
           
        })
	  }
  }
}
</script>

<style lang="scss" scope>

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        .addcard{
            cursor: pointer;
            color: #ff7c00;
        }
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 0;
		}
        .editinfo{
           .e1{
               display: flex;
               margin: 15px 0;
               >div{
                   display: flex;
                   align-items: center;
                   
                   >div{
                       display: flex;
                       p{
                           width: 80px;
                            text-align: justify;
                            text-align-last: justify;
                        }
                        span{
                                color: #ff7c00;
                        }
                        
                   }
                   input{
                        width: 240px;
                        height: 40px;
                        margin: 0 60px 0 20px;
                        background: #e6e6e6;
                        border-radius: 4px;
                        text-indent: 15px;
                   }
                   >p{
                       margin-left: 20px;
                       color: #ff7c00;
                       cursor: pointer;
                   }
                  
               }
           }
           .e2{
               display: flex;
               div{
                    margin-right: 35px;
               }
              textarea{
                  width: 650px;
                  height: 80px;
                  background: #e6e6e6;;
                  border-radius: 4px;
                 padding: 10px;
                 font-size: 14px;
              }

           }

        }
       
        
        
        .but{
            display: flex;
            margin-top: 50px;
            div{
                width: 150px;
                height: 45px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
               
                line-height: 45px;
            }
            .edit{
                 color: #999999;
                border: 1px solid #999999;
                margin-right: 30px;
            }
            .editpass{
                color: #fff;
                background: #ff7c00;
                border: 1px solid #ff7c00;
            }
        }
		
	}

</style>
<style>
.el-input__inner{
        -webkit-appearance: none;                             
        height: 40px;
        margin: 0 60px 0 20px;
        background: #f8f8f8;
        border-radius: 4px;
        text-indent: 5px;
        border: none;
        padding-left: 10px;
}
.el-icon-date:before {
    content: "";
}
.el-select{
    width: 260px;
}
</style>
